
import { Vue, Component, Ref, Prop, Emit } from 'vue-property-decorator';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import Button from '@/components/ui/button';
import { NamedItem, IdentifiedItem } from '@/models/inputs';
import levenshteinFilter from '@/utils/levenshteinFilter';
import ContextMenu from '../ContextMenu.vue';

@Component({
    components: { ContextMenuLink, ContextMenuHeader, Button },
})
export default class FindOrCreate extends Vue {
    $parent: ContextMenu;

    @Prop({ required: true }) items: Array<NamedItem & IdentifiedItem>;
    @Prop() newItem: string;
    @Prop({default: true }) hideCommonDirections: boolean;
    @Prop({ default: false }) hideItemCreate: boolean;
    @Ref() readonly input!: HTMLElement;
    @Ref() readonly list!: HTMLElement;

    public search = '';
    
    get hasExactMatch() {
        return this.items.map((item) => item.name.toLowerCase()).includes(this.search.trim().toLowerCase());
    }
    get filtered() {
        return levenshteinFilter(this.items.filter((el) => el.id != -111), this.search);
    }

    mounted() {
        // this.input.focus();
    }

    choose() {
        if (this.search && this.filtered.length === 1) {
            this.select(this.filtered[0].id as number);
        }
    }

    @Emit('create')
    create(name: string) {
        this.$parent.close();
        return name;
    }

    @Emit('select')
    select(id: number) {
        this.$parent.close();
        return id;
    }

    @Emit('openEditor')
    openEditor() {
        this.$parent.close();
        return true;
    }
}
