
import { Prop, Vue, Emit, Component } from 'vue-property-decorator';
import { SubRole, Role } from '@/typings/domain';

@Component
export default class Submenu extends Vue
{
	@Prop({ default: [], required: true }) actualRoles: Array<Role>;
	@Prop({ default: false }) chooseByID: boolean;
	@Prop( { default: false }) hideAllSpecs: boolean;
	@Prop( { default: false }) hideForAll: boolean;
	@Prop() submenuStyles: object;
	@Prop() selected: any;

	public correctionLeft = 0;
	public correctionTop = 0;
	public submenuPosition: Array<string> = [];

	@Emit('add')
	addSpecialisation(spec: SubRole | number)
	{
		return spec;
	}

	@Emit('forAll')
	selectAllRoles(): boolean
	{
		return true;
	}

	@Emit('allSpecs')
	selectAllSpecs(list: Array<SubRole>): {ids: Array<number>, roleName: string, list: Array<SubRole>}
	{
		const ids = list.map((item: SubRole) => item.id);
		const roleName = this.actualRoles.find(el => el.id === list[0].role_id).name

		return {ids, roleName, list};
	}

	mouseover(e: Event, idx: number)
	{
		const target = e.target as HTMLElement;
		const wrapper = target.closest('.roles-menu__wrapper');

		this.submenuPosition[idx] = (target.offsetTop - wrapper.scrollTop - 16) + 'px';
		this.submenuPosition = [...this.submenuPosition];
	}
}
